<template>
  <div>
    <el-form :model="form"
             label-width="60px">
      <el-row :gutter="10">
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="科目">
            <el-select style="width:100%"
                       v-model="form.subjectId"
                       clearable
                       @change="getSourceList"
                       placeholder="选择科目">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="来源">
            <el-select style="width:100%"
                       v-model="form.sourceId"
                       filterable
                       clearable
                       placeholder="选择来源">
              <el-option v-for="item in sourceList"
                         :key="item.source_id"
                         :label="item.source_name"
                         :value="item.source_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="5"
                :md="11"
                :sm="11"
                :xl="5"
                :xs="24">
          <el-input clearable
                    placeholder="题目id"
                    @keyup.enter.native="search"
                    type="number"
                    v-model.number="form.questionId" />
        </el-col>
        <el-col :span="6">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="refresh">刷新</el-button>

        </el-col>
      </el-row>
    </el-form>
    <el-table border
              stripe
              :data="tableData"
              highlight-current-row
              :max-height="$store.state.tableHeight + 30"
              style="width: 100%">
      <el-table-column prop="question_id"
                       align="center"
                       v-if="tableColumns[0].isShow"
                       width="100"
                       label="题目ID" />
      <el-table-column prop="subject_name"
                       align="center"
                       v-if="tableColumns[1].isShow"
                       width="100"
                       label="科目" />
      <el-table-column prop="source_name"
                       v-if="tableColumns[2].isShow"
                       align="center"
                       label="题目来源" />
      <el-table-column prop="filename"
                       v-if="tableColumns[3].isShow"
                       align="center"
                       label="文件名称">
        <template slot-scope="{row}">
          <div class="line"
               @click="preview(row)">{{row.filename}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="type"
                       align="center"
                       v-if="tableColumns[4].isShow"
                       width="120"
                       label="文件类型" />
      <el-table-column prop="file_size"
                       v-if="tableColumns[5].isShow"
                       align="center"
                       width="120"
                       label="大小" />
      <el-table-column prop="created_time"
                       v-if="tableColumns[6].isShow"
                       align="center"
                       label="上传时间	" />
      <el-table-column prop="description"
                       v-if="tableColumns[7].isShow"
                       align="center"
                       show-overflow-tooltip
                       label="描述">
        <template slot-scope="scope">
          <div @click="cut(scope.$index)"
               v-if="scope.row.isActive"
               class="table_line">
            {{scope.row.description ?scope.row.description:'&nbsp;'}}
          </div>
          <span v-else>
            <el-input clearable
                      ref="table_line_ref"
                      @blur="save(scope.$index)"
                      v-model="scope.row.description" />
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center"
                       v-if="tableColumns[8].isShow"
                       width="200"
                       label="操作">
        <template slot="header">
          <i class="el-icon-setting"
             slot="header"
             @click="showController"></i>
          操作
        </template>
        <template slot-scope="{row}">
          <div class="operyion_btn_icon">
            <el-tooltip effect="dark"
                        content="预览"
                        placement="top-start">
              <img src="@/assets/icons/icon-priview@2x.png"
                   @click="preview(row)"
                   alt="">
            </el-tooltip>

            <span style="width:20px;height:20px"></span>
            <el-tooltip effect="dark"
                        content="查看题目"
                        placement="top-start">
              <img src="@/assets/icons/icon-look@2x.png"
                   @click="lookTopic(row)"
                   alt="">
            </el-tooltip>
            <span style="width:20px;height:20px"></span>
            <el-tooltip effect="dark"
                        content="修改"
                        placement="top-start">
              <img src="@/assets/icons/icon-edit@2x.png"
                   @click="edit(row)"
                   alt="">
            </el-tooltip>
            <span style="width:20px;height:20px"></span>
            <el-tooltip effect="dark"
                        content="删除"
                        placement="top-start">
              <img src="@/assets/icons/icon-delete@2x.png"
                   @click="delRow(row)"
                   alt="">
            </el-tooltip>
            <span style="width:20px;height:20px"></span>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <Shuttle ref="ShuttleRef" />

    <Info ref="InfoRef" />
    <editForm ref="editFormRef" />
    <previewDialog ref="previewDialogRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>
<script>
import Info from '@/components/checkInfo/index.vue'
import previewDialog from '../schoolSource/components/preview.vue'
import editForm from './components/editForm.vue'
export default {
  data () {
    return {
      form: {
        subjectId: '',
        sourceId: '',
        questionId: ''
      },
      break: '',
      infoForm: {},
      subjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      sourceList: [],
      pageName: 'cz_microlecture_list_admin',
      tableColumns: [
        { label: '题目ID', isShow: true },
        { label: '科目', isShow: true },
        { label: '题目来源', isShow: true },
        { label: '文件名称', isShow: true },
        { label: '文件类型', isShow: true },
        { label: '大小', isShow: true },
        { label: '上传时间', isShow: true },
        { label: '描述', isShow: true },
        { label: '操作', isShow: true },

      ],
    }
  },
  components: {
    previewDialog, Info, editForm
  },
  created () {
    var info = window.localStorage.getItem(this.pageName)
    if (info) {
      this.tableColumns = JSON.parse(info)
    }
  },
  mounted () {
    this.getSubject()
  },
  methods: {
    preview (row) {
      this.$refs.previewDialogRef.info = row
      this.$refs.previewDialogRef.setIframe()
      this.$refs.previewDialogRef.text = row.filename
      this.$refs.previewDialogRef.url = row.url
      this.$refs.previewDialogRef.dialogVisible = true
    },
    lookTopic (row) {
      this.$router.push('/microlecture/list?id=' + row.question_id)
      this.$refs.InfoRef.dialogVisible = true
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        // 删除科目项第一项是因为所有的来源太多了，会造成页面卡顿
        this.subjectList = res.data.subject
      })
    },
    getSourceList () {
      this.form.sourceId = ''
      if (this.form.subjectId == '') {
        this.sourceList = []
        return
      }
      this.$http({
        url: '/api/v1/question/choices',
        method: 'POST',
        data: {
          subject_id: this.form.subjectId
        }
      }).then(res => {
        this.sourceList = res.data.paper_source
      })
    },
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      this.$http({
        url: '/api/v1/course/lst',
        method: 'post',
        data: this.form
      }).then(res => {
        this.tableData = res.data.list
        for (var index = 0; index < this.tableData.length; index++) {
          this.tableData[index].isActive = true
        }
        this.totalElements = res.data.count
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    edit (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.editFormRef.form = form
      this.$refs.editFormRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((() => {
        this.$http({
          url: '/api/v1/course/del',
          method: 'POST',
          data: {
            id: row.id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      }))
    },
    cut (idx) {
      for (var index = 0; index < this.tableData.length; index++) {
        this.tableData[index].isActive = true
      }
      this.tableData[idx].isActive = false
      this.break = this.tableData[idx].description
      setTimeout(() => {
        this.$refs.table_line_ref.focus()
      }, 300);
    },
    save (index) {
      var obj = this.tableData[index]
      if (this.break == obj.description) {
        var obj = JSON.parse(JSON.stringify(this.tableData[index]))
        obj.isActive = true
        this.$set(this.tableData, index, obj)
        return
      }
      this.$http({
        url: '/api/v1/course/edit',
        method: 'post',
        data: {
          id: obj.id,
          question_id: obj.question_id,
          source_id: obj.source_id,
          subject_id: obj.subject_id,
          description: obj.description
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '修改成功!',
          type: 'success'
        });
        this.refresh()
      })
    },
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
.table_line {
  width: 100%;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
</style>